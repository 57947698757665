<template>
      <div v-if="items && items.length" class="row mini-gallery-container">
        <div class="mini-gallery">
          <div
            v-for="(item, index) in items"
            v-bind:key="index"
            v-bind:class="['mini-gallery__item', getItemClass(item)]"
          >
            <lang-flag v-bind:iso="item" />
          </div>
        </div>
      </div> 
</template>
<script>
import LangFlag from "vue-lang-code-flags";
export default {
  name: "TableLanguageList",
  components:{
    LangFlag
  },
  props: {
    items: {
      default: () => [],
    },
  },
  methods: {
    getItemClass(item) {
      const itemClass = {
        tr: "alert-danger",
        en: "alert-primary",
      };

      if (itemClass.hasOwnProperty(item)) return itemClass[item];
      else return "bg-light";
    },
  },
};
</script>
<style lang="scss" scoped>
.mini-gallery {
  display: flex;
  position: relative;
}
.mini-gallery__item {
  width: 30px;
  height: 30px;
  border-radius: 100%;
  transition: 0.4s all;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid transparent;
  font-weight: 500;
  &:hover {
    box-shadow: 1px 3px 5px rgba(0, 0, 0, 0.4);
  }
  span {
    user-select: none;
  }
}
.mini-gallery__item {
  position: relative;
  &:hover {
    border: 1px dashed gray;
    z-index: 1;
  }
}
.mini-gallery__item:not(:first-child) {
  margin-left: -10px;
}
.mini-gallery-container {
  position: relative;
}
</style>